<template>
    <el-row>
        <el-col class="support-banner">
            <div class="text-center">
                <p class="service-title1">专业团队 优质服务</p>
                <p class="service-title2">让客户满意是我们永远的承诺</p>
            </div>
        </el-col>
        <el-col>
            <div class="service-info">
                <div class="service-card">
                    <div class="card-left"><img src="../../img/servicesupport/service_img1.png"></div>
                    <div class="card-right">
                        <p class="title-bold">服务支持</p>
                        <p class="service-content2">
                            1、专业性：伊登成立专属项目运维团队，指定专属服务负责人，建立详细运维保障体系，并提供方案。<br>
                            2、便捷性：运维沟通方式便捷化，包括不限于：电话、微信群、QQ群、邮件方式。<br>
                            3、响应性：接到通报故障的通知后，2小时内给予响应。<br>
                            4、时效性：系统提供1年免费运维服务，7x24小时，电话和邮件技术支持，并提供原因分析。
                        </p>
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-left"><img src="../../img/servicesupport/service_img2.png"></div>
                    <div class="card-right">
                        <p class="title-bold">增值服务</p>
                        <p class="service-content2">
                            产品经理一对一进行需求调研、方案设计，根据客户业务场景的需求，
                            提供结合业务场景的使用方案； 项目经理协助客户进行平台的搭建和支持 ，
                            高效快捷地帮助客户更好的使用文件管理平台。
                        </p>
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-left"><img src="../../img/servicesupport/service_img3.png"></div>
                    <div class="card-right">
                        <p class="title-bold">培训服务</p>
                        <p class="service-content2">
                            企业专属的顾问级线上培训服务、线下等多形式、全方位帮助企业全面
                            了解产品价值和产功能使用。
                        </p>
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-left"><img src="../../img/servicesupport/service_img3.png"></div>
                    <div class="card-right">
                        <p class="title-bold">VIP服务</p>
                        <p class="service-content2">
                            客户专属的服务顾问为您提供贴心操作指导，定期回访关怀，新功能推送， 推荐使用场景和面向客户文件管理提供优化方案，帮助客户获得持续成功。
                        </p>
                    </div>
                </div>
            </div>

        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "ServiceSupport",
    data() {
      return {

      }
    },
    methods: {
        tryApply() {
            this.$router.push({path: '/TryApply'})
        }
    }
}
</script>

<style lang="less" scoped>

    * {
        font-size: 16px
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    .support-banner {
        background: url("../../img/servicesupport/service_bg.png") no-repeat  top;
        background-size:  100% 100%;
        min-height: 400px;
        display: flex;
        /*从上往下排列*/
        flex-direction: column;
        /*上下居中*/
        justify-content: center;
        /*左右居中*/
        align-items: center;
    }

    .service-title1 {
        font-size: 40px;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 1;
    }

    .service-title2 {
        font-size: 16px;
        color: #FFFFFF;
        opacity: 1;
    }

    .service-title3 {
        width: 192px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #FFFFFF;
        line-height: 40px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
    }

    .service-info {
        max-width: 1200px;
        width: 100%;
        margin: auto;
    }

    .service-content2 {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
    }

    .service-card {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        align-items: center;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }

    .card-left {
        flex-grow: 1;
        flex-basis: 50%;
        text-align: center;
    }

    .card-right {
        flex-grow: 1;
        flex-basis: 40%;
        text-align: left;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .service-info .service-card:nth-child(2n) .card-left{
        order: 1;
    }

    @media screen and (max-width: 1200px){
        .service-info {
            max-width: 650px;
            width: 100%;
            margin: auto;
        }

        .card-left {
            order: 1;
        }
    }

    @media screen and (max-width: 500px){
        .service-info {
            max-width: 650px;
            width: 100%;
            margin: auto;
        }

        .service-info img {
            width: 80%;
        }

        .service-content2 {
            font-size: 12px;
            color: #414141;
        }
        .title-bold {
            font-size: 14px !important;
            font-weight: 400 !important;
        }

        .support-banner {
            background: url("../../img/servicesupport/service_bg_mobile.png") no-repeat top;
            min-height: 196px;
        }
    }
</style>